<template>
  <potager-modal :without-footer="!getUserCoupons.length">
    <template #header>
      <div class="text-left">
        <template v-if="!isMobile || !getUserCoupons.length">
          <p class="mb-2 uppercase">
            Ajouter un coupon de réduction
          </p>

          <potager-form
            id="add-coupon"
            @onSubmit="onSubmit">
            <potager-input
              v-model="couponCode"
              :constraints="constraints"
              :inline-submit-gtm="{ label: 'ajouter un coupon', couponCode }"
              :is-loading="isLoading"
              inline-submit="OK"
              label="Code coupon"
              name="code"
              reset />
          </potager-form>
        </template>

        <template v-else>
          <p class="mb-2 uppercase">
            Mes coupons
          </p>

          <potager-button
            v-if="isMobile"
            full-width
            label="Ajouter un coupon"
            theme="stroke"
            @onClick="openNewCouponModal" />
        </template>
      </div>
    </template>

    <template
      v-if="getUserCoupons.length"
      #body>
      <div class="text-left">
        <p
          v-if="!isMobile"
          class="mb-2 uppercase">
          Mes coupons
        </p>

        <coupons-selector
          :context="context"
          class="text-0 leading-0 mb-4 bp768:mb-6" />

        <p class="text-xs.5 leading-tight text-warm-grey">
          Attention, si le montant cumulé des coupons sélectionnés est supérieur au montant de votre commande, la
          différence ne sera pas remboursée et sera perdue. Pour éviter de perdre la différence lors du paiement, vous
          aurez jusqu’au {{ tmsToFormat(getLimitDate, 'eeee dd MMMM') }}, 23h59 pour compléter votre commande en
          ajoutant d’autres paniers
          depuis l’espace « Mon compte ».
        </p>
      </div>
    </template>

    <template
      v-if="getUserCoupons.length"
      #footer="{ close }">
      <potager-button
        :label="getSubscriptionBasketCoupons.length ? 'Appliquer' : 'Continuer'"
        full-width
        @onClick="close" />
    </template>
  </potager-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION } from 'Stores/types/userActionsTypes';

import GtmMixin from 'Mixins/GtmMixin';

import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerForm from 'UI/Form/PotagerForm';

import CouponsSelector from 'Components/coupons/CouponsSelector';

import { tmsToFormat } from 'PotagerLogic/Utils/Dates/DateFormat';

import ModalSubscriptionNewCoupon from 'Modals/ModalSubscriptionNewCoupon';
import { Context } from 'PotagerLogic/Enums/Context';

export default {

  mixins: [
    GtmMixin,
  ],

  components: {
    PotagerModal,
    PotagerButton,
    PotagerInput,
    PotagerForm,
    CouponsSelector,
  },

  data: () => ({
    couponCode: null,
    constraints: [
      {
        constraint: 'required',
        field: 'code'
      },
      {
        constraint: 'length',
        field: 'code',
        options: { min: 3 }
      },
    ],
    context: Context.Subscription
  }),

  computed: {
    ...mapGetters('user', [
      'getSubscriptionBasket',
      'getUserCoupons',
    ]),
    ...mapGetters('subscriptionBasket', [
      'getSubscriptionBasketCoupons',
      'getLimitDate'
    ]),
    isLoading() {
      return this.$wait.is([
        ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
      ]);
    },
    isMobile() {
      return this.$mq.bp1024;
    },
  },

  methods: {
    tmsToFormat,
    onSubmit(code = null) {
      return new Promise((resolve) => {
        const couponCode = (code || this.couponCode)?.toUpperCase();
        this.$store.dispatch(`user/${ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION}`, { couponCode })
          .then((response) => {
            this.$events.emit('request:inputSuccess', {
              field: 'code',
              message: 'Le coupon a été ajouté',
            });
            this.trackAction({
              event: 'addNewCoupon',
              name: 'modal',
              value: couponCode,
            });
            resolve(response);
          });
      });
    },
    openNewCouponModal() {
      this.$modal.open(ModalSubscriptionNewCoupon, {
        submit: this.onSubmit,
      });
    },
  },

};
</script>
