<template>
  <header
    :class="`main-header print:hidden main-header--${$route?.name}`">
    <div class="main-header__primary p-2">
      <dlp-resume condensed />
    </div>

    <div class="main-header__secondary flex items-center justify-between w-full px-4 py-2.5 border-t border-white-rock">
      <main-header-burger
        :active="isMenuVisible"
        class="mr-4"
        @toggleMenu="toggleMenu" />

      <div
        class="main-header__logo z-10 mr-auto">
        <potager-link
          :to="
            {
              name:
                'home'
            }">
          <transition
            mode="out-in"
            name="appear-from-top">
            <potager-logo
              v-if="!$mq.bp480"
              key="logo"
              :class="$parent.mutators.includes('transparent-bianca') ? 'text-bianca' : undefined" />

            <potager-logo
              v-else
              key="logo-minimal"
              :class="$parent.mutators.includes('transparent-bianca') ? 'text-bianca' : undefined"
              minimal />
          </transition>
        </potager-link>
      </div>

      <main-header-actions />
    </div>

    <main-header-alerts />

    <potager-notifications />
  </header>

  <transition name="fade">
    <div
      v-if="isMenuVisible"
      class="overlay z-100"
      @click="closeMenu" />
  </transition>

  <transition name="appear-from-left">
    <div
      v-if="isMenuVisible"
      class="sidepanel h-screen">
      <div
        class="main-header__panel">
        <div class="panel__navigation">
          <p class="panel__navigation__title">
            Menu
          </p>

          <main-header-burger
            :active="isMenuVisible"
            class="panel__close"
            theme="stroke"
            @toggleMenu="toggleMenu" />
        </div>

        <ul class="!text-base p-6 pt-0">
          <li
            v-for="(item, index) in [...menu.left, ...menu.right]"
            :key="`menu-item-${index}`"
            class="border-solid border-0 border-b border-white-rock last:border-0 last:mb-0 first:-mt-6">
            <potager-button
              v-if="!item.children?.some((child) => child.children)"
              :target="item.target"
              :to="item.to"
              class="text-base my-6"
              full-width
              no-padding
              theme="white">
              <span class="flex w-full">
                <span class="mr-3 font-bold">
                  {{ item.label }}
                </span>
              </span>
            </potager-button>

            <ul v-if="item.children">
              <li
                v-for="(subItem, subIndex) in item.children"
                :key="subIndex"
                :class="[
                  'mb-4',
                  {
                    'border-t border-white-rock first:border-0': subItem.children
                  }
                ]">
                <template v-if="subItem.children">
                  <p class="text-base font-bold my-6">
                    {{ subItem.label }}
                  </p>

                  <ul class="flex flex-col gap-4">
                    <li
                      v-for="(secondChild, k) in subItem.children"
                      :key="`secondChild-${k}`">
                      <potager-button
                        :to="secondChild.to"
                        class="text-left flex-1 justify-start"
                        no-padding
                        theme="white">
                        <template
                          v-if="secondChild.icon"
                          #icon>
                          <potager-picture
                            :src="secondChild.icon"
                            :title="secondChild.title"
                            class="w-5 h-5" />
                        </template>

                        <span class="flex w-full font-semibold">
                          {{ secondChild.label }}
                        </span>
                      </potager-button>
                    </li>
                  </ul>
                </template>

                <potager-button
                  v-else
                  :to="subItem.to"
                  class="text-left flex-1 justify-start"
                  no-padding
                  theme="white">
                  <template
                    v-if="subItem.icon"
                    #icon>
                    <potager-picture
                      :src="subItem.icon"
                      :title="subItem.title"
                      class="w-5 h-5" />
                  </template>

                  <span class="flex w-full font-semibold">
                    {{ subItem.label }}
                  </span>
                </potager-button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>

import { stuckPage, getWindowScrollTop } from 'Classes/utils/ScrollUtils';

import PotagerLogo from 'UI/PotagerLogo';
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';

import { props as navigationProps } from 'Components/mainHeader/MainHeaderNavigation';
import MainHeaderBurger from 'Components/mainHeader/MainHeaderBurger';
import MainHeaderActions from 'Components/mainHeader/MainHeaderActions';
import DlpResume from 'Components/deliveryPoint/DlpResume';
import MainHeaderAlerts from 'Components/mainHeader/MainHeaderAlerts';
import PotagerNotifications from 'Components/notification/PotagerNotifications';

export default {
  components: {
    PotagerNotifications,
    DlpResume,
    MainHeaderActions,
    MainHeaderBurger,
    PotagerButton,
    PotagerLogo,
    PotagerPicture,
    MainHeaderAlerts,
  },

  data: () => ({
    isMenuVisible: false,
  }),

  props: {
    ...navigationProps,
  },

  watch: {
    isMenuVisible: {
      handler(val) {
        const posTop = this.getHeaderPos();
        if (val && posTop) window.scrollTo({ top: getWindowScrollTop() + posTop });

        let stuck = this.$potagerRoute.meta.isPanel || this.$potagerRoute.meta.isFlying || val;
        stuckPage(stuck);
      },
      immediate: true,
    },
  },

  methods: {
    getHeaderPos() {
      return this.$parent.$refs.header ? this.$parent.$refs.header.getBoundingClientRect().top : 0;
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    closeMenu() {
      this.isMenuVisible = false;
    },
  },

  created() {
    this.$events.on('closeMenu', () => {
      this.closeMenu();
    });
    this.$events.on('toggleMenu', () => {
      this.toggleMenu();
    });
  }
};
</script>
