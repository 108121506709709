<template>
  <ul class="coupons-selector">
    <template v-for="(group, i) in getGroupedCoupons">
      <template v-if="group.coupons && group.coupons.length">
        <li :key="`title-${i}`">
          <p
            v-if="group.title"
            class="coupons-selector__title">
            {{ group.title }}
          </p>
          <p
            v-if="group.legend && group.coupons.length > 1"
            class="coupons-selector__legend">
            {{ group.legend }}
          </p>
        </li>
        <coupon-checkbox
          v-for="coupon in group.coupons"
          :key="`${i}-${coupon.id}-${resetKey}`"
          :coupon="coupon"
          :is-checkable="!isLoading"
          :is-checked="coupon.isChecked"
          :is-disabled="isCouponDisabled(coupon)"
          :is-loading="isCouponLoading(coupon)"
          @onChecked="onChecked" />
      </template>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';

import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';

import {
  ADD_COUPON_ON_BASKET_ACTION,
  ADD_COUPON_ON_ORDER_ACTION,
  ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
  REMOVE_COUPON_ON_BASKET_ACTION,
  REMOVE_COUPON_ON_ORDER_ACTION,
  REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
} from 'Stores/types/userActionsTypes';

import CouponCheckbox from 'Components/coupons/CouponCheckbox';

import { Context } from 'PotagerLogic/Enums/Context';
import { getCouponsGroupedByType, getForbiddenTypes } from 'PotagerLogic/Utils/Order/OrderCoupons';
import { CouponType, CouponValueType } from 'PotagerLogic/Enums/Coupons';

export default {

  components: {
    CouponCheckbox,
  },

  props: {
    context: {
      type: String,
      required: true,
      default: Context.Basket,
    },
    autoCheckNewCoupon: {
      type: Boolean,
      required: false,
      default: false,
    },
    order: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data: () => ({
    resetKey: 0,
  }),

  computed: {
    ...mapGetters('user', [
      'getUserCoupons'
    ]),
    getCoupons() {
      const coupons = uniqBy([
        ...this.getUserCoupons,
        ...this.getContextCoupons,
        ...(this.context === Context.Basket ? this.$store.getters['basket/getRemovedCoupons'] : []),
      ], 'id');
      return coupons.map((coupon) => ({
        ...coupon,
        isChecked: this.isCouponChecked(coupon),
      }))
        .reverse();
    },
    getGroupedCoupons() {
      return getCouponsGroupedByType(this.getCoupons);
    },
    getContextCoupons() {
      switch (this.context) {
        case Context.Subscription:
          return this.$store.getters['subscriptionBasket/getSubscriptionBasketCoupons'];
        case Context.Order:
          return this.order ? this.$store.getters['user/getOrderCoupons'](this.order.id) : [];
        default:
          return this.$store.getters['basket/getSimulatedOrderCoupons'];
      }
    },
    getAddAction() {
      switch (this.context) {
        case Context.Subscription:
          return ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION;
        case Context.Order:
          return ADD_COUPON_ON_ORDER_ACTION;
        default:
          return ADD_COUPON_ON_BASKET_ACTION;
      }
    },
    getRemoveAction() {
      switch (this.context) {
        case Context.Subscription:
          return REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION;
        case Context.Order:
          return REMOVE_COUPON_ON_ORDER_ACTION;
        default:
          return REMOVE_COUPON_ON_BASKET_ACTION;
      }
    },
    isLoading() {
      return this.$wait.is([
        this.getAddAction,
        this.getRemoveAction,
      ]);
    },
  },

  watch: {
    getUserCoupons(newVal, oldValue) {
      if (newVal !== oldValue && this.autoCheckNewCoupon) {
        const newCoupon = newVal.filter((obj) => !oldValue.some((obj2) => obj.id === obj2.id));
        if (newCoupon && newCoupon.length === 1 && !this.isCouponDisabled(newCoupon[0])) {
          this.onChecked({
            isChecked: true,
            coupon: newCoupon[0],
          });
        }
      }
    },
  },

  methods: {
    isCouponLoading(coupon) {
      return this.$wait.is([
        `${this.getAddAction}_${coupon.id}`,
        `${this.getAddAction}_${coupon.couponCode}`,
        `${this.getRemoveAction}_${coupon.id}`,
        `${this.getRemoveAction}_${coupon.couponCode}`,
      ]);
    },
    isCouponDisabled(coupon) {
      if (coupon.isChecked) return false;
      return this.isCouponDisabledByType(coupon)
        || this.isCouponDisabledByValueType(coupon)
        || this.isCouponDisabledByLimitLuncheonReached(coupon);
    },
    isCouponDisabledByType(coupon) {
      if (!this.getContextCoupons.length) return false;
      const forbiddenTypes = this.getContextCoupons.flatMap((c) => getForbiddenTypes(c.couponType));
      return forbiddenTypes.includes(coupon.couponType);
    },
    isCouponDisabledByValueType(coupon) {
      if (this.getContextCoupons.length) {
        const isForbidden = this.getContextCoupons.filter((e) => {
          // list of cases
          const isValueAndValue = (e.couponValueType === CouponValueType.Value && coupon.couponValueType === CouponValueType.Value);
          const isValueAndProduct = (e.couponValueType === CouponValueType.Value && coupon.couponValueType === CouponValueType.Product)
            || (e.couponValueType === CouponValueType.Product && coupon.couponValueType === CouponValueType.Value);
          const isDifferentTypes = e.couponType !== coupon.couponType;
          const isProductAndPercent = (e.couponValueType === CouponValueType.Percent && coupon.couponValueType === CouponValueType.Product)
            || (e.couponValueType === CouponValueType.Product && coupon.couponValueType === CouponValueType.Product);

          // allowed cases
          const isCombinable = isValueAndValue || isValueAndProduct || (isDifferentTypes && isProductAndPercent);
          return !isCombinable;
        }).length;
        if (isForbidden) return true;
      }
      return false;
    },
    isCouponDisabledByLimitLuncheonReached(coupon) {
      if (this.getContextCoupons.length && coupon.couponType === CouponType.Luncheon) {
        return filter(this.getContextCoupons, { couponType: CouponType.Luncheon }).length > 1;
      }
      return false;
    },
    isCouponChecked(coupon) {
      return filter(this.getContextCoupons, ['id', coupon.id]).length > 0;
    },
    getAction(isChecked) {
      return isChecked ? this.getAddAction : this.getRemoveAction;
    },
    onChecked({
      isChecked,
      coupon,
    }) {
      const action = this.getAction(isChecked);

      const params = {
        couponId: coupon.id,
        couponCode: coupon.couponCode,
      };
      if (this.order) params.orderId = this.order.id;

      this.$store.dispatch(`user/${action}`, params)
        .catch(() => this.resetKey += 1);
    },
  },

};
</script>
