<template>
  <div class="panel-section">
    <p class="panel-section__title">
      Récapitulatif
    </p>

    <potager-receipt-details
      :lines="receiptLines" />

    <template v-if="!readOnly">
      <div class="panel-section__separator" />

      <panel-section-navigator
        :to="{ name: 'basket_coupons' }"
        class="panel-section__title">
        Code promo

        <template #right>
          <potager-button
            :to="{ name: 'basket_coupons' }"
            is-rounded
            size="small"
            theme="stroke">
            {{ getSimulatedOrder?.coupons.length ? 'Modifier' : 'Ajouter' }}
          </potager-button>
        </template>
      </panel-section-navigator>

      <potager-receipt-details
        v-if="getSimulatedOrder?.coupons.length"
        :lines="couponsLines" />
    </template>

    <div class="panel-section__separator" />

    <potager-receipt-details
      :lines="[totalLine]" />

    <subscriber-benefits-navigator
      v-if="!readOnly"
      :order="order" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerReceiptDetails from 'UI/PotagerReceiptDetails';
import PotagerButton from 'UI/PotagerButton';

import SubscriberBenefitsNavigator from 'Components/subscriberBenefits/SubscriberBenefitsNavigator';
import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator';

import {
  getCouponsListLines,
  getReceiptLines,
  getTotalLine
} from 'PotagerLogic/Utils/Order/OrderReceiptDetails';

export default {
  name: 'BasketReceiptDetails',
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PanelSectionNavigator,
    PotagerButton,
    SubscriberBenefitsNavigator,
    PotagerReceiptDetails,
  },
  computed: {
    ...mapGetters('basket', [
      'getSimulatedOrder',
      'getLinkedOrder',
      'getBasket',
    ]),
    receiptLines() {
      return getReceiptLines(this.order, {
        linkedOrder: this.getLinkedOrder,
        completionOrder: this.getBasket,
        showCoupons: this.readOnly,
      });
    },
    couponsLines() {
      return this.order ? getCouponsListLines(this.order) : [];
    },
    totalLine() {
      return this.order ? getTotalLine(this.order) : [];
    },
    order() {
      return this.getSimulatedOrder || this.getBasket;
    },
  },
};
</script>
