import { getOrderPrice } from 'PotagerLogic/Utils/Order/OrderPrices';
import { tmsToFormat } from 'PotagerLogic/Utils/Dates/DateFormat';
import { addDaysToTimestamp } from 'PotagerLogic/Utils/Dates/DateCalculation';
import { SUBSCRIPTION, SUBSCRIPTION_ENTERPRISE } from 'Classes/Constants';
import BoxGtmAdapter from 'Classes/adapters/BoxGtmAdapter';

import {
  UPDATE_BASKET_ACTION,
  ADD_BOX_BASKET_ACTION,
  REMOVE_BOX_BASKET_ACTION,
  EDIT_BOX_QUANTITY_BASKET_ACTION,
  UPDATE_DELIVERY_POINT_BASKET_ACTION,
  SET_BASKET_EXPIRATION_ACKNOWLEDGE_ACTION,
  EMPTY_BASKET_ACTION,
} from 'Stores/types/basketActionsTypes';

import {
  UPDATE_BASKET,
  UPDATE_DELIVERY_POINT,
  UPDATE_GROUPDELIVERY_DAY,
  UPDATE_PENDING_DELIVERY_POINT,
} from 'Stores/types/basketMutationsTypes';

import { UPDATE_SESSION_USER_DATA_ACTION } from 'Stores/types/sessionActionsTypes';

import ModalBasketPdlChangeOutOfStock from 'Modals/ModalBasketPdlChangeOutOfStock';
import ModalBasketSuppression from 'Modals/ModalBasketSuppression';

import { api } from 'Plugins/potagerApiClient';
import { openModal } from 'Plugins/potagerModals';
import {
  getBasketState,
  getBasketStatusMessageType,
  getBasketStatusMessage
} from 'PotagerLogic/Utils/Basket/BasketStatus';

const DEFAULT_PRICE = {
  subTotal: '0.00',
  total: '0.00',
  totalCoupons: '0.00',
};

const DEFAULT_EXPIRATION = {
  expirationDate: null,
  expirationTimer: 0,
  clearedAt: null,
  aknowledgmentDate: null,

};

export default {
  namespaced: true,
  state: {
    address: null,
    customerSubscription: null,
    debitDate: null,
    products: [],
    coupons: [],
    priceOneShot: DEFAULT_PRICE,
    priceOneShotEnterprise: DEFAULT_PRICE,
    priceSubscription: DEFAULT_PRICE,
    priceSubscriptionEnterprise: DEFAULT_PRICE,
    priceCategory: 'priceOneShot',
    deliveryPoint: null,
    groupDeliveryDay: null,
    deliveryDateSelectedIndex: -1,
    expiration: DEFAULT_EXPIRATION,
    linkedOrder: null,
    canContainMiniBox: false,
    simulatedOrder: null,
    removedCoupons: [],
    pendingDeliveryPoint: null,
    pendingGroupDeliveryDay: null,
    limitDate: null,
  },

  getters: {
    getAddress: (state) => state.address,
    getBasket: (state) => state,
    getBasketState: (state, getters, rootState, rootGetters) => getBasketState(state, { activeOrders: rootGetters['user/getActiveOrders'] }),
    getBasketStateByContext: (state, getters, rootState, rootGetters) => (context) => getBasketState(state, {
      activeOrders: rootGetters['user/getActiveOrders'],
      context
    }),
    getBasketStateType: (state, getters, rootState, rootGetters) => getBasketStatusMessageType(state, { activeOrders: rootGetters['user/getActiveOrders'] }),
    getBasketStateTypeByContext: (state, getters, rootState, rootGetters) => (context) => getBasketStatusMessageType(state, {
      activeOrders: rootGetters['user/getActiveOrders'],
      context
    }),
    getBasketMessage: (state, getters, rootState, rootGetters) => getBasketStatusMessage(state, { activeOrders: rootGetters['user/getActiveOrders'] }),
    getBasketMessageByContext: (state, getters, rootState, rootGetters) => (context) => getBasketStatusMessage(state, {
      activeOrders: rootGetters['user/getActiveOrders'],
      context
    }),
    getBasketAddress: (state) => state.address,
    getRegionId: (state) => (state.deliveryPoint == null ? 1 : state.deliveryPoint.regionId),
    getProducts: (state) => state.products,
    getPriceOneShot: (state) => state.priceOneShot,
    getPriceSubscription: (state) => state.priceSubscription,
    getPriceCategory: (state) => state.priceCategory,
    getBasketCoupons: (state) => state.coupons,
    getTotal: (state) => getOrderPrice(state)?.total,
    getSubTotal: (state) => getOrderPrice(state)?.subTotal,
    getCouponsTotal: (state) => getOrderPrice(state)?.totalCoupons,
    getDeliveryPoint: (state) => state.deliveryPoint,
    getGroupDeliveryDay: (state) => state.groupDeliveryDay,
    getSelectedGdd: (state) => state.groupDeliveryDay,
    getLimitDate: (state) => {
      const timestamp = state.limitDate || state.groupDeliveryDay?.timeSlot?.date;
      if (timestamp) {
        return addDaysToTimestamp(timestamp, state.limitDate ? 0 : -2);
      } else {
        return null;
      }
    },
    getAvailableOrderableDate: (state) => state.groupDeliveryDay?.timeSlot?.availableOrderableDate,
    isNextDeliveryAvailable: (state) => state.groupDeliveryDay?.isNextDeliveryAvailable,
    getDebitDate: (state) => (state.debitDate ? tmsToFormat(state.debitDate, 'eeee dd MMMM') : null),
    getDeliveryDate: (state) => state.groupDeliveryDay?.timeSlot?.date ?? 0,
    getExpiration: (state) => state.expiration,
    getLinkedOrder: (state) => state.linkedOrder,
    getLinkedOrderProducts: (state) => (state.linkedOrder ? state.linkedOrder.boxes : []),
    isSubscription: (state) => [SUBSCRIPTION, SUBSCRIPTION_ENTERPRISE].includes(state.priceCategory),
    getSaving: (state, getters) => getters.getPriceOneShot.total - getters.getPriceSubscription.total,
    canContainMiniBox: (state) => state.canContainMiniBox,
    getSimulatedOrder: (state) => state.simulatedOrder,
    getSimulatedOrderCoupons: (state) => state.simulatedOrder?.coupons,
    getSimulatedOrderCouponsTotal: (state) => getOrderPrice(state.simulatedOrder)?.totalCoupons,
    getSimulatedOrderSubTotal: (state) => getOrderPrice(state.simulatedOrder)?.subTotal,
    getSimulatedOrderTotal: (state) => getOrderPrice(state.simulatedOrder)?.total,
    getRemovedCoupons: (state) => state.removedCoupons,
  },

  mutations: {
    [UPDATE_BASKET](state, payload) {
      state.address = payload.address;
      state.customerSubscription = payload.customerSubscription;
      state.debitDate = payload.debitDate;
      state.products = payload.products || [];
      state.coupons = payload.coupons || [];
      state.priceOneShot = payload.priceOneShot || DEFAULT_PRICE;
      state.priceOneShotEnterprise = payload.priceOneShotEnterprise || DEFAULT_PRICE;
      state.priceSubscription = payload.priceSubscription || DEFAULT_PRICE;
      state.priceSubscriptionEnterprise = payload.priceSubscriptionEnterprise || DEFAULT_PRICE;
      state.priceCategory = payload.priceCategory || 'priceOneShot';
      state.regionId = payload.regionId || 1;
      state.groupDeliveryDay = payload.groupDeliveryDay;
      state.deliveryPoint = payload.deliveryPoint;
      state.expiration = payload.expiration || DEFAULT_EXPIRATION;
      state.linkedOrder = payload.linkedOrder;
      state.canContainMiniBox = payload.canContainMiniBox || false;
      state.simulatedOrder = payload.simulatedOrder;
      state.removedCoupons = payload.removedCoupons || [];
      state.limitDate = payload.limitDate;
    },
    [UPDATE_DELIVERY_POINT](state, payload) {
      state.deliveryPoint = payload;
    },
    [UPDATE_GROUPDELIVERY_DAY](state, payload) {
      state.groupDeliveryDay = payload;
    },
    [UPDATE_PENDING_DELIVERY_POINT](state, payload) {
      state.pendingDeliveryPoint = payload.deliveryPoint;
      state.pendingGroupDeliveryDay = payload.groupDeliveryDay;
    },
  },

  actions: {
    [UPDATE_BASKET_ACTION]({ commit }, payload) {
      commit(UPDATE_BASKET, payload);
    },
    [ADD_BOX_BASKET_ACTION]({
      commit,
      dispatch,
      rootGetters,
    }, {
      idRegion,
      idBox,
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', `${ADD_BOX_BASKET_ACTION}_${idBox}`, { root: true });
        dispatch('wait/start', ADD_BOX_BASKET_ACTION, { root: true });

        const addBox = rootGetters['user/getUserId']
          ? () => api.user.addBox(idRegion, idBox)
          : () => api.temporaryCustomer.addBox(rootGetters['temporaryCustomer/getTemporaryCustomerId'], idRegion, idBox);

        addBox()
          .then((resp) => {
            // GTM
            // find which box has been added
            const box = resp.data.data.basket.products
              .find((b) => idBox === b.id);
            const boxAdapted = new BoxGtmAdapter(this, {
              box,
              parameters: { index: -1 },
            });
            const gtmData = {
              event: 'add_to_cart',
              currency: 'EUR',
              value: boxAdapted.price,
              items: [boxAdapted],
            };

            dispatch(UPDATE_BASKET_ACTION, { ...resp.data.data.basket, ...{ meta: { gtm: gtmData } } });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            dispatch('wait/end', `${ADD_BOX_BASKET_ACTION}_${idBox}`, { root: true });
            dispatch('wait/end', ADD_BOX_BASKET_ACTION, { root: true });
          });
      });
    },
    [REMOVE_BOX_BASKET_ACTION]({
      commit,
      dispatch,
      rootState,
      rootGetters,
    }, {
      idRegion,
      idBox,
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', `${REMOVE_BOX_BASKET_ACTION}_${idBox}`, { root: true });
        dispatch('wait/start', REMOVE_BOX_BASKET_ACTION, { root: true });

        const deleteBox = rootGetters['user/getUserId']
          ? () => api.user.removeBox(idRegion, idBox)
          : () => api.temporaryCustomer.removeBox(rootGetters['temporaryCustomer/getTemporaryCustomerId'], idRegion, idBox);

        deleteBox()
          .then((resp) => {
            // GTM
            // find which box has been deleted
            const box = rootState.basket.products.find((b) => idBox === b.id);
            const boxAdapted = new BoxGtmAdapter(this, {
              box,
              parameters: { index: -1 },
            });
            const gtmData = {
              event: 'remove_from_cart',
              currency: 'EUR',
              value: boxAdapted.price,
              items: [boxAdapted],
            };

            commit(UPDATE_BASKET, { ...resp.data.data.basket, ...{ meta: { gtm: gtmData } } });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', `${REMOVE_BOX_BASKET_ACTION}_${idBox}`, { root: true });
            dispatch('wait/end', REMOVE_BOX_BASKET_ACTION, { root: true });
          });
      });
    },
    [EDIT_BOX_QUANTITY_BASKET_ACTION]({
      commit,
      dispatch,
      rootGetters,
    }, {
      idRegion,
      idBox,
      quantity,
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', `${EDIT_BOX_QUANTITY_BASKET_ACTION}_${idBox}`, { root: true });
        dispatch('wait/start', EDIT_BOX_QUANTITY_BASKET_ACTION, { root: true });
        const beforeQuantity = rootGetters['basket/getBasket'].products.filter((b) => b.id === idBox).length;

        const editBoxQuantity = rootGetters['user/getUserId']
          ? () => api.user.editBoxQuantity(idRegion, idBox, quantity)
          : () => api.temporaryCustomer.editBoxQuantity(rootGetters['temporaryCustomer/getTemporaryCustomerId'], idRegion, idBox, quantity);

        editBoxQuantity()
          .then((resp) => {
            // GTM
            // find which box has been edited
            const box = resp.data.data.basket.products.find((b) => idBox === b.id);
            const boxAdapted = new BoxGtmAdapter(this, {
              box,
              parameters: { index: -1 },
            });
            const event = quantity > beforeQuantity ? 'add_to_cart' : 'remove_from_cart';

            const gtmData = {
              event,
              currencyCode: 'EUR',
              value: boxAdapted.price,
              items: [{
                ...boxAdapted,
                quantity: quantity - beforeQuantity,
              }],
            };

            commit(UPDATE_BASKET, { ...resp.data.data.basket, ...{ meta: { gtm: gtmData } } });
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => {
            dispatch('wait/end', `${EDIT_BOX_QUANTITY_BASKET_ACTION}_${idBox}`, { root: true });
            dispatch('wait/end', EDIT_BOX_QUANTITY_BASKET_ACTION, { root: true });
          });
      });
    },
    [UPDATE_DELIVERY_POINT_BASKET_ACTION]({
      rootGetters,
      dispatch,
    }, {
      regionId,
      groupDeliveryDayId,
      address = null,
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', UPDATE_DELIVERY_POINT_BASKET_ACTION, { root: true });

        const setBasketDeliveryPoint = (simulated = false) => {
          const setBasketDeliveryPointApi = rootGetters['user/getUserId']
            ? () => api.user.setBasketDeliveryPoint(regionId, groupDeliveryDayId, simulated, address)
            : () => api.temporaryCustomer.setBasketDeliveryPoint(rootGetters['temporaryCustomer/getTemporaryCustomerId'], regionId, groupDeliveryDayId, simulated, address);

          return setBasketDeliveryPointApi();
        };

        const changeDlpPromise = () => {
          dispatch('wait/start', UPDATE_DELIVERY_POINT_BASKET_ACTION, { root: true });

          return setBasketDeliveryPoint(false)
            .then((response) => {
              dispatch(`session/${UPDATE_SESSION_USER_DATA_ACTION}`, response, { root: true });
              resolve(response);
            })
            .catch((err) => {
              console.error(err);
              reject(err);
            })
            .finally(() => dispatch('wait/end', UPDATE_DELIVERY_POINT_BASKET_ACTION, { root: true }));
        };

        setBasketDeliveryPoint(true)
          .then((resp) => {
            const { basket } = resp.data.data;
            const newNbProduct = basket.products.length;
            const newRegionId = basket.deliveryPoint.regionId;

            if (resp.data.warning.length) {
              const filteredProducts = resp.data.warning.filter((w) => ['BT017', 'BT015'].includes(w.code));
              const products = filteredProducts
                .map((w) => w.metadata)
                .filter((v, i, a) => a
                  .findIndex((t) => (t.boxId === v.boxId)) === i);

              if (newNbProduct === 0) {
                openModal(ModalBasketSuppression, {
                  newRegionId,
                  groupDeliveryDayId,
                  action: changeDlpPromise,
                  cancelHandler: () => {
                    dispatch('wait/end', UPDATE_DELIVERY_POINT_BASKET_ACTION, { root: true });
                  },
                });
              } else {
                openModal(ModalBasketPdlChangeOutOfStock, {
                  products,
                  action: changeDlpPromise,
                  cancelHandler: () => {
                    dispatch('wait/end', UPDATE_DELIVERY_POINT_BASKET_ACTION, { root: true });
                  },
                });
              }
            } else {
              changeDlpPromise();
            }
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', UPDATE_DELIVERY_POINT_BASKET_ACTION, { root: true }));
      });
    },
    [SET_BASKET_EXPIRATION_ACKNOWLEDGE_ACTION]({ commit }) {
      return new Promise((resolve, reject) => {
        api.user.setBasketCheck(localStorage.getItem('token'))
          .then((resp) => {
            commit(UPDATE_BASKET, resp.data.data.basket);
            resolve(resp);
          })
          .catch((err) => reject(err));
      });
    },
    [EMPTY_BASKET_ACTION]({
      commit,
      dispatch,
      rootGetters,
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', EMPTY_BASKET_ACTION, { root: true });
        const emptyBasket = rootGetters['user/getUserId']
          ? () => api.user.clearBasket()
          : () => api.temporaryCustomer.clearBasket(rootGetters['temporaryCustomer/getTemporaryCustomerId']);

        emptyBasket()
          .then((resp) => {
            commit(UPDATE_BASKET, resp.data.data.basket);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', EMPTY_BASKET_ACTION, { root: true }));
      });
    },
  },
};
