<template>
  <div class="autocomplete-input">
    <base-input
      ref="input"
      v-model="value"
      :list="autocompleteId"
      v-bind="$props"
      @change="$emit('change', $event)"
      @onBlur="$emit('onBlur', $event)"
      @onEnter="$emit('onEnter', $event)"
      @onError="$emit('onError', $event)"
      @onFocus="$emit('onFocus', $event)"
      @onKeyDown="$emit('onKeyDown', $event)"
      @onKeyUp="$emit('onKeyUp', $event)"
      @onPrefixClick="$emit('onPrefixClick', $event)"
      @onReset="$emit('onReset', $event)"
      @onSubmit="$emit('onSubmit', $event)" />
    <datalist
      v-if="value && value.length > 0"
      :id="autocompleteId">
      <option
        v-for="option in computedOptions"
        :key="option"
        :value="option" />
    </datalist>
  </div>
</template>

<script>

import { propsBuilder } from 'UI/Tools';
import InputMixin from 'UI/Form/Input/InputMixin';
import BaseInput from 'UI/Form/Input/BaseInput';

import levenSort from 'leven-sort';
import { ref } from 'vue';

export const props = {
  options: {
    type: Array,
    default: () => [],
  },
  maxAutocomplete: {
    type: Number,
    default: 9,
  },
};

export default {
  name: 'AutocompleteInput',
  props: propsBuilder(props),
  emits: ['change', 'onBlur', 'onEnter', 'onError', 'onFocus', 'onKeyDown', 'onKeyUp', 'onPrefixClick', 'onReset', 'onSubmit', 'onAutocomplete'],
  setup() {
    const uid = ref(Date.now());
    return { uid };
  },
  components: { BaseInput },
  mixins: [InputMixin],
  computed: {
    autocompleteId() {
      return `autocomplete-input--${this.uid}`;
    },
    computedOptions() {
      const noDuplicatesOptions = [...new Set(this.options)];
      // filter first case-sensitive & secondary by lower-casing everything
      const sortedOptions = levenSort([...noDuplicatesOptions.map((option) => ({
        default: option,
        lower: option.toLowerCase()
      }))], this.value, 'default', this.value.toLowerCase(), 'lower')
        .slice(0, this.maxAutocomplete)
        .map((obj) => obj.default);
      this.$emit('onAutocomplete', sortedOptions);
      return sortedOptions;
    },
  },
};
</script>
