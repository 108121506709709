<template>
  <panel
    :back-route="{ name: 'mon-compte' }"
    title="Coupons">
    <template #before>
      <status-banner type="warning">
        <span v-html="Wordings.COUPONS.INFORMATION_BANNER" />
      </status-banner>
    </template>

    <div class="panel-section">
      <p
        v-if="!getUserCouponsExceptLuncheon.length"
        class="panel-section__p">
        Aucun coupon n’est encore associé à votre compte.
      </p>

      <p
        v-else
        class="panel-section__title">
        {{ pluralize(getUserCouponsExceptLuncheon.length, 'coupon enregistré', 'coupons enregistrés') }}
      </p>

      <coupon-collapsible
        v-for="coupon in getUserCouponsExceptLuncheon"
        :key="coupon.id"
        :coupon="coupon" />

      <div
        v-if="!getUserCouponsExceptLuncheon?.length"
        class="panel-section__separator" />

      <panel-section-navigator
        :to="{ name: 'mon-compte_coupons_add' }">
        Ajouter un coupon
      </panel-section-navigator>
    </div>

    <div
      v-if="hasSubscriptionEnterpriseDiscount"
      class="panel-section">
      <p class="panel-section__title">
        1 Avantage entreprise
      </p>

      <coupon-collapsible force-open>
        <template #title>
          Avantage entreprise
        </template>
        <template #value>
          -{{ formatPrice(getSubscriptionEnterpriseDiscountValue) }}
        </template>
        <template #subtitle>
          {{ formatPrice(getSubscriptionEnterpriseDiscountValue) }} de remise
        </template>
        <template #comment>
          Utilisable : en illimité pour toutes les commandes qui comportent un
          panier en formule d’abonnement et livrées sur un point de retrait privé.
        </template>
      </coupon-collapsible>
    </div>

    <coupon-automated-resume />
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';

import { pluralize, formatPrice } from 'PotagerLogic/Formatting';
import Wordings from 'PotagerLogic/Constants/Wordings';

import Panel from 'Components/panel/Panel';
import CouponCollapsible from 'Components/coupons/CouponCollapsible';
import CouponAutomatedResume from 'Components/coupons/CouponAutomatedResume';
import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator';
import StatusBanner from 'Components/statusBanner/StatusBanner';

import MetaInfosService from 'Classes/services/MetaInfoService';

export default {
  components: {
    StatusBanner,
    PanelSectionNavigator,
    Panel,
    CouponAutomatedResume,
    CouponCollapsible,
  },

  data() {
    return {
      Wordings,
    };
  },

  computed: {
    ...mapGetters('user', [
      'getUserCouponsExceptLuncheon',
      'hasSubscriptionEnterpriseDiscount',
      'getSubscriptionEnterpriseDiscountValue',
    ]),
  },

  methods: {
    pluralize,
    formatPrice,
  },

  head: MetaInfosService.generate({
    title: 'Coupons',
  }),

};
</script>
